define("formaviva-web/models/track", ["exports", "ember-data", "formaviva-web/config/environment", "ember-uuid", "formaviva-web/utils/generate-random-token", "formaviva-web/constants/common", "ember-cp-validations"], function (_exports, _emberData, _environment, _emberUuid, _generateRandomToken, _common, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.build = _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var Validations = (0, _emberCpValidations.buildValidations)({
    artists: [(0, _emberCpValidations.validator)("presence", true)]
  });

  var _default = Model.extend(Validations, {
    recordType: "track",
    price: attr("number"),
    mix: attr("boolean"),
    allowPurchase: attr("boolean"),
    allowDownloadInPrivateMode: attr("boolean"),
    allowFreeDownloadWithSecret: attr("boolean"),
    tracksInMix: attr(undefined, {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    uuid: attr("string"),
    name: attr("string"),
    description: attr("string"),
    createdAt: attr("date"),
    lowQualityUrl: attr("string", {
      readOnly: true
    }),
    highQualityUrl: attr("string", {
      readOnly: true
    }),
    slug: attr("string"),
    imageUrl: attr("string"),
    fileName: attr("string"),
    imageUploadId: attr("string"),
    releasePrices: attr(),
    metadata: attr(),
    audioAnalysis: attr(),
    processed: attr("boolean"),
    playCount: attr("number"),
    liked: attr("boolean"),
    likedAt: attr("date"),
    publishedAt: attr("date"),
    likesCount: attr("number"),
    private: attr("boolean", {
      defaultValue: true
    }),
    privateToken: attr("string"),
    // When a track is linked with multiple releases,
    // this attribute will always contain the
    // first release that is linked with this track
    // set inverse to null so assignments in release doesn't
    // get affected
    release: belongsTo("release", {
      inverse: null,
      async: false
    }),
    releaseId: attr("string"),
    profile: belongsTo("profile"),
    artistNames: attr("string"),
    originalArtistNames: attr("string"),
    artists: hasMany("profile"),
    tags: hasMany("tag"),
    remixerIds: attr("array"),
    profileSlug: Ember.computed.alias("profile.slug"),
    nameWithArtist: Ember.computed.alias("displayName"),
    displayName: attr("string"),
    mixes: hasMany("track", {
      async: false
    }),
    playlists: hasMany("playlist"),
    originalArtists: Ember.computed("remixerIds", "artists", function () {
      var _this = this;

      return this.get("artists").filter(function (artist) {
        return !_this.get("remixerIds").includes(parseInt(artist.id));
      });
    }),
    sortedTracksInMix: Ember.computed("tracksInMix.[]", function () {
      return this.tracksInMix.sortBy("start_time");
    }),
    //serialized ember-data track records in mix
    tracksInMixRecords: Ember.computed("sortedTracksInMix.[]", "store", function () {
      var _this2 = this;

      return this.sortedTracksInMix.map(function (_ref) {
        var track_id = _ref.track_id,
            track_data = _ref.track_data;

        if (track_id) {
          return _this2.store.peekRecord("track", track_id);
        } else {
          return {
            id: track_data.name,
            artists: [],
            name: track_data.name,
            profileName: track_data.profile_name,
            displayName: track_data.display_name,
            artistNames: track_data.artist_names
          };
        }
      });
    }),
    loadedRelease: Ember.computed("release", "releaseId", function () {
      try {
        var release = this.get("release");

        if (release && release.isEmpty === false) {
          return release;
        } else {
          return this._loadRelease();
        }
      } catch (e) {
        return this._loadRelease();
      }
    }),
    _loadRelease: function _loadRelease() {
      var _this3 = this;

      var releaseId = this.get("releaseId");

      if (!releaseId) {
        return null;
      }

      return this.store.findRecord("release", releaseId).then(function (loadedRelease) {
        _this3.set("release", loadedRelease);

        _this3.set("loadedRelease", loadedRelease);

        return loadedRelease;
      });
    },
    removeMixTrack: function removeMixTrack(track) {
      var tracksInMix = this.tracksInMix;
      var index = -1;

      for (var i = 0; i < tracksInMix.length; i++) {
        var t = tracksInMix[i];
        var id = t.track_id || t.track_data.name;

        if (id + "" === track.id) {
          index = i;
          break;
        }
      }

      tracksInMix.removeAt(index);
    },
    addMixTrack: function addMixTrack(track, index) {
      var tracksInMix = this.tracksInMix,
          sortedTracksInMix = this.sortedTracksInMix,
          tracksInMixRecords = this.tracksInMixRecords;

      if (tracksInMixRecords.findBy("name", track.name) && tracksInMixRecords.findBy("artistNames", track.artistNames)) {
        return false;
      }

      if (index !== undefined && index !== null) {
        var oldTrack = sortedTracksInMix[index];
        var labelName = Ember.isPresent(oldTrack.profile) ? oldTrack.profile.get("name") : "";
        Ember.set(oldTrack, "track_id", track.id);
        Ember.set(oldTrack, "track_data.name", track.name);
        Ember.set(oldTrack, "track_data.display_name", track.displayName);
        Ember.set(oldTrack, "track_data.artist_names", track.artistNames || "");
        Ember.set(oldTrack, "track_data.profile_name", labelName || "");
        this.notifyPropertyChange("tracksInMixRecords");
      } else {
        var start_time = sortedTracksInMix.length > 0 ? (sortedTracksInMix.lastObject.start_time || 0) + (tracksInMixRecords.lastObject.length || 10) : 0;

        var _labelName = Ember.isPresent(track.profile) ? track.profile.get("name") : "";

        tracksInMix.pushObject({
          track_id: track.id,
          start_time: start_time,
          track_data: {
            name: track.name,
            profile_name: _labelName || "",
            display_name: track.displayName,
            artist_names: track.artistNames || ""
          }
        });
      }

      return true;
    },
    adjustedImageUrl: Ember.computed("imageUrl", "release.adjustedImageUrl", function () {
      var imageUrl = this.imageUrl;

      if (imageUrl && imageUrl[0] === "/") {
        return "".concat(_environment.default.serverURL, "/").concat(imageUrl);
      } else if (imageUrl) {
        return imageUrl;
      } else {
        // because release async:false and has inverse: null it is throwing error
        // when release.adjustedImageUrl is accessed when release is not loaded yet
        // this happens at ember-data: 3.4.1, ember-source: 3.5.1
        var adjustedImageUrl;

        try {
          adjustedImageUrl = this.get("release.adjustedImageUrl"); // eslint-disable-next-line
        } catch (e) {}

        return adjustedImageUrl;
      }
    }),
    canonicalUrl: Ember.computed("profile.slug", "slug", function () {
      return "".concat(_environment.default.webURL, "/").concat(this.get("profile.slug"), "/").concat(this.get("slug"));
    }),
    length: Ember.computed.alias("metadata.length"),
    peaks: Ember.computed("processed", "audioAnalysis", function () {
      var data = this.get("audioAnalysis");

      if (data) {
        return data.peaks;
      }

      return "";
    }),
    purchased: attr("boolean"),
    purchaseItem: belongsTo("purchase-item")
  });

  _exports.default = _default;

  var build = function build(store) {
    return store.createRecord("track", {
      uuid: (0, _emberUuid.v4)(),
      privateToken: (0, _generateRandomToken.default)(_common.PRIVATE_TOKEN_LENGTH),
      artists: [],
      remixerIds: []
    });
  };

  _exports.build = build;
});