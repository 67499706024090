define("formaviva-web/_pods/components/track/track-main/component", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showTrackStats: _environment.default.featureFlags.showTrackStats
  });

  _exports.default = _default;
});