define("formaviva-web/_pods/playlist/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    playlistActions: Ember.inject.service("playlist/playlist-actions"),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    playlist: Ember.computed.alias("model"),
    currentSession: Ember.inject.service(),
    allTracksPrice: 0,
    tracksForPurchase: null,
    actions: {
      addAllTracksToCart: function addAllTracksToCart() {
        var _this = this;

        this.get("tracksForPurchase").forEach(function (track) {
          _this.send("addToCart", track, track.loadedRelease);
        });
      },
      addToCart: function addToCart(track, release) {
        var _this2 = this;

        if (track) {
          if (track.mix) this.shoppingCart.addMixToCart(track);else this.shoppingCart.addTrackToCart(track, release);
        } else {
          // need to reload release to get all details otherwise addReleaseToCart/addMerchToCart is not working
          release.reload().then(function (release) {
            _this2.shoppingCart.addReleaseToCart(release);
          });
        }
      }
    }
  });

  _exports.default = _default;
});