define("formaviva-web/_pods/components/tables/tracks-table/component", ["exports", "formaviva-web/mixins/track-download", "formaviva-web/_pods/components/player/player-slave/component"], function (_exports, _trackDownload, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_trackDownload.default, {
    notifications: Ember.inject.service("notification-messages"),
    trackActions: Ember.inject.service("track/track-actions"),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    track: Ember.computed.alias("model"),
    media: Ember.inject.service(),
    playerHeight: 28
  });

  _exports.default = _default;
});